import React, { useEffect } from "react";
import { useAsyncFn } from "react-use";
import { useIntersection } from "react-use";
import styles from "./PicturePreview.module.css";

type PicturePreviewProps = {
  file: ProjectFile;
  onClick(file: ProjectFile): void;
};

const PicturePreview = ({ file, onClick }: PicturePreviewProps) => {
  const intersectionRef = React.useRef(null);
  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: "0px",
    threshold: 1,
  });

  const [{ value, loading }, load] = useAsyncFn(async (): Promise<{
    url: string;
    size: number;
  }> => {
    const buffer = await window.api.previewPicture(file.id);
    return {
      url: URL.createObjectURL(new Blob([buffer])),
      size: buffer.byteLength,
    };
  }, [file]);

  useEffect(() => {
    if (!value && !loading) {
      if (intersection && intersection.intersectionRatio > 0.05) {
        load();
      }
    }
  }, [value, loading, intersection, load]);

  return (
    <div
      ref={intersectionRef}
      className={styles.preview}
      style={{
        maxWidth: (file.width * 140) / file.height,
        minWidth: (file.width * 140) / file.height,
      }}
      onClick={() => onClick(file)}
    >
      {value && <img alt={file.file} key={file.id} src={value.url} />}
    </div>
  );
};

export default PicturePreview;
