import { useProject } from "./hooks/useProject";
import { Home } from "./screens/Home";
import { Project } from "./screens/Project";

function App() {
  const [project] = useProject();

  if (!project) {
    return <Home />;
  }

  return <Project />;
}

export default App;
