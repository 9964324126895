import { useAsync } from "react-use";
import { Loading } from "../../components/Loading";
import styles from "./PictureModal.module.css";

type PictureModalProps = {
  file: ProjectFile;
  onClick(): void;
};

const DownloadIcon = () => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      fill="currentColor"
      d="M16.965 2.381c3.593 1.946 6.035 5.749 6.035 10.119 0 6.347-5.153 11.5-11.5 11.5s-11.5-5.153-11.5-11.5c0-4.37 2.442-8.173 6.035-10.119l.608.809c-3.353 1.755-5.643 5.267-5.643 9.31 0 5.795 4.705 10.5 10.5 10.5s10.5-4.705 10.5-10.5c0-4.043-2.29-7.555-5.643-9.31l.608-.809zm-4.965-2.381v14.826l3.747-4.604.753.666-5 6.112-5-6.101.737-.679 3.763 4.608v-14.828h1z"
    />
  </svg>
);

const MB = 1024 * 1024;

const PictureModal = ({ file, onClick }: PictureModalProps) => {
  const { value, loading } = useAsync(async (): Promise<{
    url: string;
    size: number;
  }> => {
    const buffer = await window.api.downloadPicture(file.id);
    return {
      url: URL.createObjectURL(new Blob([buffer])),
      size: buffer.byteLength,
    };
  }, [file]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className={styles.backdrop} onClick={onClick}>
      <div>
        <img alt="preview" src={value?.url} />
        <div className={styles.tool}>
          {value && (
            <a
              href={value?.url}
              download={file.file}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <DownloadIcon />
              Tải xuống ({((value?.size || 0) / MB).toFixed(2)}MB)
            </a>
          )}
        </div>
      </div>
    </div>
  );
};
export default PictureModal;
