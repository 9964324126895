import { createStateContext, useAsync } from "react-use";

const [useProject, ProjectInternalProvider] =
  createStateContext<Project | null>(null);

type ProjectProviderProps = {
  children: React.ReactElement;
};

const ProjectProvider = ({ children }: ProjectProviderProps) => {
  const { value: project, loading } = useAsync(
    () => window.api.getProject(),
    []
  );

  if (loading) {
    return null;
  }

  return (
    <ProjectInternalProvider initialValue={project}>
      {children}
    </ProjectInternalProvider>
  );
};

export { useProject, ProjectProvider };
