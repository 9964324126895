import { useEffect } from "react";
import { useAsyncFn, useAsyncRetry } from "react-use";
import { Loading } from "../../components/Loading";
import { useProject } from "../../hooks/useProject";
import styles from "./Home.module.css";

const FolderIcon = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill-rule="evenodd"
    clip-rule="evenodd"
  >
    <path
      fill="currentColor"
      d="M0 2h8l3 3h10v4h3l-4 13h-20v-20zm22.646 8h-17.907l-3.385 11h17.907l3.385-11zm-2.646-1v-3h-9.414l-3-3h-6.586v15.75l3-9.75h16z"
    />
  </svg>
);

const Home = () => {
  const [, setProject] = useProject();
  const [{ loading }, openProject] = useAsyncFn(
    async () => window.api.openProject(),
    []
  );
  const { value, retry } = useAsyncRetry(window.api.getAppState);
  useEffect(() => {
    let timeId: any = null;
    retry();
    if (loading) {
      timeId = setInterval(retry, 100);
    }

    return () => {
      clearInterval(timeId);
    };
  }, [loading, retry]);

  return (
    <div className={styles.root}>
      {loading && <Loading title={value?.text} />}
      <div className={styles.title}>
        <h1>Magikpic</h1>
        <label>Ứng dụng chỉnh sửa ảnh tự động</label>
      </div>
      <div className={styles.actions}>
        <p>
          Để bắt đầu chỉnh sửa anh, chọn thư mục chứa ảnh cần chỉnh sửa tự động.
        </p>
        <button
          disabled={loading}
          onClick={async () => {
            setProject(await openProject());
          }}
        >
          <FolderIcon />
          Chọn thư mục
        </button>
      </div>
      <div className={styles.intro}>
        <h2>Hướng dẫn sử dụng</h2>
        <ol>
          <li>Bấm "Chọn thư mục" để mở thư mục chứa ảnh</li>
          <li>Để tải một ảnh: Bấm chọn ảnh rồi tải về</li>
          <li>
            Để xuất toàn bộ ảnh trong thư mục: Chọn Lưu tất cả, ảnh sẽ được lưu
            trong thư mục out nằm trong thư mục gốc.
          </li>
        </ol>
      </div>
    </div>
  );
};

export default Home;
