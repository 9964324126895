import styles from "./Loading.module.css";

type LoadingProps = {
  title?: string;
};

const Loading = ({ title = "Yêu cầu đang được xử lý..." }: LoadingProps) => (
  <div className={styles.backdrop}>
    <div className={styles.skChase}>
      <div className={styles.skChaseDot}></div>
      <div className={styles.skChaseDot}></div>
      <div className={styles.skChaseDot}></div>
      <div className={styles.skChaseDot}></div>
      <div className={styles.skChaseDot}></div>
      <div className={styles.skChaseDot}></div>
    </div>
    <div>{title}</div>
  </div>
);

export default Loading;
