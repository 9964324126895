import { useEffect, useState } from "react";
import { Loading } from "../../components/Loading";
import PicturePreview from "./PicturePreview";
import PictureModal from "./PictureModal";
import { useProject } from "../../hooks/useProject";
import styles from "./Project.module.css";
import { useAsyncFn, useAsyncRetry } from "react-use";

const SaveAllIcon = () => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      fill="currentColor"
      d="M6 16h-5v6h22v-6h-5v-1h6v8h-24v-8h6v1zm14 2c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm-7.5-17v14.884l4.736-5.724.764.645-5.979 7.195-6.021-7.205.765-.644 4.735 5.732v-14.883h1z"
    />
  </svg>
);

const CloseIcon = () => (
  <svg
    viewBox="0 0 24 24"
    fill-rule="evenodd"
    clip-rule="evenodd"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="currentColor"
      d="M12 0c6.623 0 12 5.377 12 12s-5.377 12-12 12-12-5.377-12-12 5.377-12 12-12zm0 1c6.071 0 11 4.929 11 11s-4.929 11-11 11-11-4.929-11-11 4.929-11 11-11zm0 10.293l5.293-5.293.707.707-5.293 5.293 5.293 5.293-.707.707-5.293-5.293-5.293 5.293-.707-.707 5.293-5.293-5.293-5.293.707-.707 5.293 5.293z"
    />
  </svg>
);

const Project = () => {
  const [project, setProject] = useProject();
  const [selectedPicture, selectPicture] = useState<ProjectFile | null>(null);
  const [{ loading }, exportProject] = useAsyncFn(
    async () => window.api.exportProject(),
    []
  );
  const { value, retry } = useAsyncRetry(window.api.getAppState);
  useEffect(() => {
    let timeId: any = null;
    retry();
    if (loading) {
      timeId = setInterval(retry, 100);
    }

    return () => {
      clearInterval(timeId);
    };
  }, [loading, retry]);

  if (!project) {
    return null;
  }

  return (
    <div className={styles.previews}>
      {loading && <Loading title={value?.text} />}
      {project.files.map((file) => {
        return (
          <PicturePreview key={file.id} file={file} onClick={selectPicture} />
        );
      })}
      {selectedPicture && (
        <PictureModal
          file={selectedPicture}
          onClick={() => selectPicture(null)}
        />
      )}
      <div className={styles.tool}>
        <select>
          <option value="yody">Yody Logo &amp; Text</option>
        </select>
        <button
          onClick={() => {
            exportProject();
          }}
        >
          <SaveAllIcon />
          Lưu tất cả
        </button>
        <div className="spacer" />
        <button
          className={styles.close}
          onClick={async () => {
            window.api.closeProject();
            setProject(null);
          }}
        >
          <CloseIcon />
          Đóng lại
        </button>
      </div>
    </div>
  );
};

export default Project;
